import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { getRenderable } from './manage';
export function withWidgetRenderable(Component, componentType) {
    return function WidgetRenderable(props) {
        const renderable = getRenderable(componentType);
        if (renderable && !renderable(props))
            return null;
        return _jsx(Component, Object.assign({}, props));
    };
}

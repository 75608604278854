/* eslint-disable @typescript-eslint/no-unused-vars */
// import { getMixinProtocol } from './manage';
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export function transformProtocol(originalProtocol, _pageInfo, _ctx) {
    return __awaiter(this, void 0, void 0, function* () {
        // 暂时已被 with-transform-protocol 局部转换取代
        // 统一转换遇到慢接口还是很慢，会导致其他组件一起变慢，目前局部转换效果更好，同时也满足当前的需求
        // 若未来需求发生变动，必须要使用统一转换时再做改变
        // if (!originalProtocol) {
        //   return null;
        // }
        // const treeData = originalProtocol.body.tree;
        // const children = treeData?.props.children;
        // if (children) {
        //   const nextChildren: any[] = [];
        //   const loads = [];
        //   for (const child of children) {
        //     if (child) {
        //       const mixinProtocol = getMixinProtocol(child.type);
        //       const promise = mixinProtocol(child, ctx, pageInfo).then((nextChild) => {
        //         if (nextChild) nextChildren.push(nextChild);
        //       });
        //       loads.push(promise);
        //     }
        //   }
        //   await Promise.all(loads);
        //   treeData.props.children = nextChildren;
        // }
        return originalProtocol;
    });
}

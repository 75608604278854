import * as widgetComponents from '@common/components/design';
import { withClientTransform, withTransformProtocol, withWidgetRenderable } from './transform';
// <withTransformProtocol>
//   <withWidgetRenderable>
//     <withClientTransform>
//       <Component />
//     </withClientTransform>
//   </withWidgetRenderable>
// </withTransformProtocol>
function wrapComponent(component, componentType) {
    return [withTransformProtocol, withWidgetRenderable, withClientTransform].reduceRight((component, hoc) => hoc(component, componentType), component);
}
const getWipComponents = () => {
    const tmp = Object.fromEntries(Object.entries(widgetComponents).map(([key, module]) => {
        return [key, wrapComponent(module, key)];
    }));
    return Object.assign(Object.assign({}, tmp), { root: 'div' });
};
export default getWipComponents;

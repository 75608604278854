/*
 * @Author: 朱璐
 * @Date: 2022-11-15 17:25:59
 * @Description: cms相关接口
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import request from '@common/application/utils/request';
import { transformProtocol } from '@common/application/utils/transform';
import { get } from 'lodash-es';
import { API_MAP } from './api-map';
export class CmsApi {
    static getHome(config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.CMS_HOME, {}, Object.assign({}, config)).then((res) => __awaiter(this, void 0, void 0, function* () {
                const pageConfigString = get(res, 'config');
                let pageConfig = JSON.parse(pageConfigString || '{}');
                pageConfig = yield transformProtocol(pageConfig, {});
                res.config = pageConfig;
                return res;
            }));
        });
    }
    static getTopic(id, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.CMS_TOPIC, { id }, Object.assign({}, config));
        });
    }
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { map as transformerMap } from './map';
export function getProtocol(componentType) {
    var _a;
    return (_a = transformerMap[componentType]) === null || _a === void 0 ? void 0 : _a.protocol;
}
export function getRequests(componentType) {
    var _a;
    return (_a = transformerMap[componentType]) === null || _a === void 0 ? void 0 : _a.requests;
}
export function getEvents(componentType) {
    var _a;
    return (_a = transformerMap[componentType]) === null || _a === void 0 ? void 0 : _a.events;
}
export function getMixinProtocol(componentType) {
    return (original, ctx, pageInfo) => __awaiter(this, void 0, void 0, function* () {
        let next = original;
        const common = getProtocol('common');
        if (common) {
            next = yield common(next, ctx, pageInfo);
        }
        const current = getProtocol(componentType);
        if (current) {
            try {
                next = yield current(next, ctx, pageInfo);
            }
            catch (e) {
                /* empty */
            }
        }
        return next;
    });
}
export function getMixinRequests(componentType) {
    const commonRequests = getRequests('common');
    const currentRequests = getRequests(componentType);
    return Object.assign(Object.assign({}, commonRequests), currentRequests);
}
export function getMixinEvents(componentType) {
    const commonEvents = getEvents('common');
    const currentEvents = getEvents(componentType);
    return Object.assign(Object.assign({}, commonEvents), currentEvents);
}
export function getRenderable(componentType) {
    var _a;
    return (_a = transformerMap[componentType]) === null || _a === void 0 ? void 0 : _a.renderable;
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useAsyncEffect } from 'ahooks';
import React, { useContext, useState } from 'react';
import { getMixinProtocol } from './manage';
import { PageInfoContext } from './page-info-context';
export function withTransformProtocol(Component, componentType) {
    return function TransformProtocol(rawProps) {
        const pageInfo = useContext(PageInfoContext);
        const [props, setProps] = useState({});
        const [loading, setLoading] = useState(true);
        useAsyncEffect(() => __awaiter(this, void 0, void 0, function* () {
            const protocol = getMixinProtocol(componentType);
            const { props } = yield protocol({ props: Object.assign({}, rawProps) }, undefined, pageInfo);
            setProps(props);
            setLoading(false);
        }), []);
        if (loading)
            return null;
        return _jsx(Component, Object.assign({}, props));
    };
}

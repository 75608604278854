import { useElementBounding } from '@common/application/hooks/use-element-bounding';
import { brand, coupon, divider, goods, imageAd, noticeBar, placeholder, sale, text, topMenu } from '@dch/design-widget-web';
import { useSize, useToggle } from 'ahooks';
import { createElement } from 'react';
import { Sticky } from '../common/sticky';
// import noticeBar from './notice-bar';
const noticeHeaderId = 'notice-header';
const TopMenu = (props) => {
    var _a;
    const [fixed, fixedActions] = useToggle(false);
    const { height } = useElementBounding(() => document.getElementById('page-header'));
    const { height: noticeHeaderHeight } = (_a = useSize(() => document.getElementById(noticeHeaderId))) !== null && _a !== void 0 ? _a : {};
    return createElement(Sticky, {
        targetClassName: fixed ? 'border-b border-black-4' : '',
        offset: height - (noticeHeaderHeight !== null && noticeHeaderHeight !== void 0 ? noticeHeaderHeight : 0),
        onFixed: fixedActions.set
    }, createElement(topMenu, props));
};
export { brand, coupon, divider, goods, imageAd, noticeBar, placeholder, sale, text, TopMenu as topMenu };

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemoizedFn, useSetState } from 'ahooks';
import React from 'react';
import { triggerEvent } from './trigger-event';
import { triggerRequest } from './trigger-request';
export function withClientTransform(Component, componentType) {
    return function ClientTransform(props) {
        var _a;
        const [dataset, setDataset] = useSetState((_a = props.dataset) !== null && _a !== void 0 ? _a : {});
        const handleRequest = useMemoizedFn((requestName, requestConfig = {}) => __awaiter(this, void 0, void 0, function* () {
            const { datasetKey = requestName, clearOldData, requestData = {} } = requestConfig;
            const oldDatasetItem = dataset[datasetKey] || {};
            const set = (data) => {
                setDataset({
                    [datasetKey]: data
                });
            };
            try {
                set({
                    loading: true,
                    data: clearOldData ? undefined : oldDatasetItem.data
                });
                const data = yield triggerRequest(componentType, requestName, requestData);
                set({
                    loading: false,
                    data
                });
                return data;
            }
            catch (error) {
                set({
                    loading: false,
                    error
                });
            }
        }));
        const handleEvent = useMemoizedFn((eventName, options) => {
            triggerEvent(componentType, eventName, options);
        });
        return (_jsx(Component, Object.assign({}, props, { dataset: dataset, triggerRequest: handleRequest, triggerEvent: handleEvent })));
    };
}
